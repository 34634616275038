import { KeyboardEvent } from "react";

export const onKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
  const currentValue = event.currentTarget.value;

  if (
    !/[0-9.]/.test(event.key) ||
    (event.key === "." && currentValue.includes("."))
  ) {
    event.preventDefault();
  }
};
