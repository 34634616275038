import styled from "@emotion/styled";

const MediaBelive = () => {
  return (
    <MediaBeliveBlock>
      <div className="title-block">Нам доверяют</div>

      <MediaBeliveStyled className="media_belive">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            gap: "150px",
            flexWrap: "wrap",
          }}
        >
          <img
            className="img"
            src={require("../../../assets/ditial.png")}
            alt="Belive"
            width={200}
            height={200}
            style={{ borderRadius: "50%" }} // Делаем круглое изображение
          />
          <img
            className="img"
            src={require("../../../assets/brandfabrica.png")}
            alt="Belive"
            width={200}
            height={200}
            style={{ borderRadius: "50%", height: "200px", objectFit: "cover" }} // Делаем круглое изображение
          />
          <img
            className="img"
            src={require("../../../assets/ildev.png")}
            alt="Belive"
            width={200}
            height={200}
            style={{ borderRadius: "50%" }} // Делаем круглое изображение
          />
        </div>
      </MediaBeliveStyled>
    </MediaBeliveBlock>
  );
};

const MediaBeliveBlock = styled.div`
  margin-top: 8rem;

  @media (max-width: 480px) {
    margin-top: 3rem;
  }
`;

const MediaBeliveStyled = styled.div`
  background-color: #fff;

  box-shadow: 0 0 0 100vmax #fff;
  clip-path: inset(0 -100vmax);

  padding-block: 10rem;

  margin-top: 5rem;

  display: flex;
  flex-direction: column;
  row-gap: 4rem;

  @media (max-width: 768px) {
    margin-top: 3rem;
  }

  @media (max-width: 480px) {
    overflow: hidden;

    padding-block: 2rem;

    .flex {
      gap: 1.6rem;
    }
  }
`;

export default MediaBelive;
