import styled from "@emotion/styled";
import MainLayouts from "../layouts/MainLayouts";
import { PrimaryButtonStyled } from "../components/atoms/UI/Buttons";
import { SlArrowRight } from "react-icons/sl";
import { useMediaQuery } from "react-responsive";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useState } from "react";

const dataForSecondSection = [
  {
    id: 1,
    icon: "box",
    title: "Производителям товара",
    description:
      "Мы помогаем вам оптимизировать логистику и доставку продукции, освобождая время для развития вашего бизнеса.",
  },
  {
    id: 2,
    icon: "store",
    title: "Стартапам и небольшим магазинам",
    description:
      "Оказываем поддержку на всех этапах продаж, помогая вашему бизнесу расти и масштабироваться.",
  },
  {
    id: 3,
    icon: "supplier",
    title: "Поставщикам на маркетплейсы",
    description:
      "Обеспечиваем быструю и эффективную доставку товаров на все крупные площадки.",
  },
  {
    id: 4,
    icon: "cart",
    title: "Оптовой торговле",
    description:
      "Управляем крупными объемами поставок, обеспечивая надежность и скорость на всех этапах.",
  },
  {
    id: 5,
    icon: "retail",
    title: "Розничной торговле",
    description:
      "Обеспечиваем вас бесперебойной логистикой и минимизацией затрат, помогая вам сосредоточиться на обслуживании клиентов.",
  },
  {
    id: 6,
    icon: "laptop",
    title: "Интернет магазинам",
    description:
      "Решаем задачи хранения, комплектации и доставки заказов, увеличивая скорость обработки и удобство для клиентов.",
  },
];

const dataForThirdSection = [
  {
    id: 1,
    image: "site",
    title: "Складский учет",
    description:
      "Остатки товаров различных размеров и цветов можно отслеживать в режиме онлайн, когда вам дадут доступ.",
  },
  {
    id: 2,
    image: "api",
    title: "Готовые модули интеграции",
    description:
      "Если вы используете свои системы учета, настройте интеграцию и создавайте заказы в привычном и комфортном интерфейсе. Мы предлагаем открытый API.",
  },
  {
    id: 3,
    image: "logo-with-number",
    title: "Надежность",
    description:
      "Альфа на рынке логистики 15 лет. Мы умеем оперативно осуществлять доставку груза и качественно организовывать складские процессы.",
  },
  {
    id: 4,
    image: "locations",
    title: "Широкая география",
    description:
      "Склады фулфилмента Альфа расположены в более чем 15 городах в 2 странах.",
  },
  {
    id: 5,
    image: "car",
    title: "Оперативность",
    description:
      "Выберите для хранения товаров любой из наших складов и комбинируйте хранение на них в разные сезоны продаж так, как вам будет удобно.",
  },
  {
    id: 6,
    image: "wb",
    title: "Работа с маркетплейсами",
    description:
      "Интеграция с маркетплейсами позволяет интернет-магазину работать по системе DBS, используя площадки как витрину и доставляя до конечного покупателя.",
  },
];

const dataForSixthSection = [
  {
    id: 1,
    icon: "backpack",
    title: "Приемка единицы товара",
    price: "Бесплатно",
    button: "",
  },
  {
    id: 2,
    icon: "cart-2",
    title: "Сборка первой единицы товара в заказе",
    price: "от 8 - 12 сом",
    button: false,
  },
  {
    id: 3,
    icon: "retake",
    title: "Сборка каждой последующей единицы товара",
    price: "10 сом",
    button: false,
  },
  {
    id: 4,
    icon: "box-3",
    title: "Хранение",
    price: "от 50 сом",
    button: false,
  },
  {
    id: 5,
    icon: "car-3",
    title: "Доставка до склада из Бишкек",
    price: "от 1500 сом",
    button: false,
  },
  {
    id: 6,
    icon: "",
    title: "",
    price: "",
    button: true,
  },
];

interface FAQItem {
  question: string;
  answer: string;
}

const faqData: FAQItem[] = [
  {
    question: "Какой минимальной партии или суммы можно начать работу?",
    answer: "Минимальная партия — от 500 единиц товара",
  },
  {
    question: "Что необходимо для начала сотрудничества?",
    answer: `
      1. Зарегистрироваться в наших офисах
      2. Ознакомиться с условиями и требованиями (минимальная партия — 500 единиц)
      3. Заключить договор
      4. Предоставить документы
      5. Внести предоплату (при необходимости)
      6. Подтвердить техническое задание
    `,
  },
  {
    question: "Есть ли ограничения по объему и типу товаров?",
    answer:
      "Мы принимаем заказы только на одежду и текстиль, с минимальным объемом от 500 единиц до 80 000 единиц в день. Все товары проходят контроль качества (ОТК) и отправляются в соответствии с требованиями",
  },
  {
    question: "Как оформить заявку на фулфилмент для моего интернет-магазина?",
    answer:
      "Написать нам, указав информацию о вашем интернет-магазине и товарах",
  },
  {
    question: "Как долго хранятся товары на складе?",
    answer:
      "Товары могут храниться на нашем складе на условиях, которые мы согласовываем с клиентом при заключении договора. Обычно длительность хранения зависит от потребностей вашего бизнеса. Возможны как краткосрочные, так и долгосрочные варианты",
  },
  {
    question: "Можно ли отслеживать статус отправок и запасов на складе?",
    answer: `
      Да, у нас предусмотрена возможность отслеживания статуса отправок и уровня запасов на складе. Мы предлагаем:
      • Личный кабинет — в нем вы сможете следить за наличием товаров на складе, статусами отправок, движением запасов и обновлениями.
      • Интеграция с интернет-магазином — если у вас есть онлайн-платформа, мы можем настроить автоматическое обновление данных о запасах и отправках для вашего удобства
    `,
  },
  {
    question:
      "Я нахожусь в другом городе. Сможете ли забрать товар и все сделать за меня?",
    answer: `
      Да, мы можем организовать забор вашего товара из любого города и полностью взять на себя процесс фулфилмента! Вот как это работает:
      • Организация забора — мы координируем забор товара с вашего склада или пункта отправки.
      • Доставка на наш склад — ваш товар будет доставлен на наш склад, где мы проверим количество и качество (ОТК).
      • Полное управление фулфилментом — обработка, хранение, упаковка и отправка ваших заказов будет выполнена нашей командой, освобождая вас от всех этих задач
    `,
  },
];

const Fulfilment = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const isTablet = useMediaQuery({ maxWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 480 });

  const toggleAccordion = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleWhatsAppClick = () => {
    const phone = "+996222990990";
    const message = "Рассчитать стоимость";
    const whatsappUrl = `https://wa.me/${phone}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <MainLayouts title="Фулфилмент">
      <StyledIntro>
        <div className="left-content">
          <div className="intro-title">
            <h1>Фулфилмент</h1>
            <h1>для маркетплейсов</h1>
          </div>

          <p className="description">
            Прием товаров, ОТК, хранение и доставка товаров для
            интернет-магазинов, отгрузка на маркетплейсы
          </p>

          <div className="buttons">
            <PrimaryButtonStyled
              onClick={handleWhatsAppClick}
              style={{
                display: "flex",
                gap: "12px",
                alignItems: "center",
                padding: "18px 40px",
              }}
            >
              Рассчитать стоимость <SlArrowRight />
            </PrimaryButtonStyled>

            <a href="tel:+996222990990" className="button-gray">
              Связаться с нами
            </a>
          </div>
        </div>

        <img src={require("../assets/intro-fulfi.png")} alt="intro" />
      </StyledIntro>

      <StyledSecondSection>
        <h1>Кому подойдет фулфилмент?</h1>

        <div className="list">
          {dataForSecondSection.map(({ id, icon, title, description }) => (
            <div key={id}>
              <img src={require(`../assets/${icon}.png`)} alt={title} />

              <h3>{title}</h3>

              <p>{description}</p>
            </div>
          ))}
        </div>

        <img
          className="fulfilment"
          src={require(`../assets/${
            isMobile
              ? "fulfilment-mobile"
              : isTablet
              ? "fulfilment-tablet"
              : "fulfilment"
          }.png`)}
          style={{
            borderRadius: isMobile ? "15px" : "",
          }}
          alt="fulfilment"
        />
      </StyledSecondSection>

      <StyledThirdSection>
        <h1>Почему мы?</h1>

        <div className="list">
          {dataForThirdSection.map(({ id, image, title, description }) => (
            <div key={id}>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={require(`../assets/${image}.png`)}
                  alt={title}
                  style={{
                    width: image === "logo-with-number" ? "90%" : "100%",
                  }}
                />
              </p>

              <h3>{title}</h3>

              <p>{description}</p>
            </div>
          ))}
        </div>
      </StyledThirdSection>

      <StyledFourthSection>
        <h1>Почему мы?</h1>

        <div className="list">
          <div>
            <img src={require("../assets/box-2.png")} alt="box" />

            <p>
              Мы осуществляем приемку и хранение товаров на складах класса А
            </p>
          </div>

          <img
            className="line"
            src={require("../assets/line.png")}
            alt="line"
          />

          <div>
            <img src={require("../assets/todo.png")} alt="todo" />

            <p>
              Проведем через ОТК, маркируем товары вашего интернет магазина, при
              необходимости клеим стикеры и вкладываем документы
            </p>
          </div>

          <img
            className="line"
            src={require("../assets/line.png")}
            alt="line"
          />

          <div>
            <img src={require("../assets/car-2.png")} alt="car" />

            <p>
              Комплектуем заказ и доставляем на маркетплейс или до конечного
              покупателя
            </p>
          </div>
        </div>
      </StyledFourthSection>

      <StyledFifthSection>
        <h1>Тарифы</h1>

        <div className="content">
          {dataForSixthSection.map(({ id, icon, title, price, button }) => {
            if (button) {
              return (
                <div key={id} className="btn">
                  <PrimaryButtonStyled
                    onClick={handleWhatsAppClick}
                    style={{
                      display: "flex",
                      gap: "12px",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      padding: "1.125rem 1.875rem",
                      fontSize: "1.5rem",
                      height: "fit-content",
                      background: "#6AB85D",
                    }}
                  >
                    Рассчитать стоимость <SlArrowRight />
                  </PrimaryButtonStyled>
                </div>
              );
            } else {
              return (
                <div key={id} className="inner">
                  <img src={require(`../assets/${icon}.png`)} alt={title} />

                  <div>
                    <p>{title}</p>
                    <span>{price}</span>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </StyledFifthSection>

      <StyledSeventhSection>
        <img src={require("../assets/logo-2.png")} alt="logo" />

        <h1>
          Получите бесплатный гайд по запуску продаж на маркетплейсах и
          консультацию по фулфилменту
        </h1>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "24px",
            flexDirection: isTablet ? "column" : "row",
          }}
        >
          <PhoneInputContainer>
            <StyledPhoneInput
              country="kg"
              placeholder="Введите номер телефона"
              inputStyle={{
                backgroundColor: "inherit",
                border: "1px solid white",
                color: "white",
                width: "300px",
              }}
              buttonStyle={{
                backgroundColor: "inherit",
                border: "none",
              }}
            />
          </PhoneInputContainer>

          <PrimaryButtonStyled
            style={{
              display: "flex",
              gap: "12px",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              fontSize: "16px",
              padding: "16px 40px",
              background: "#6AB85D",
            }}
          >
            Получить гайд <SlArrowRight />
          </PrimaryButtonStyled>
        </div>
      </StyledSeventhSection>

      <StyledFAQSection>
        <h1>Вопросы - ответы</h1>
        {faqData.map((item, index) => (
          <AccordionItem
            key={index}
            question={item.question}
            answer={item.answer}
            isOpen={openIndex === index}
            onClick={() => toggleAccordion(index)}
            isLastItem={index === faqData.length - 1}
            isSecondLastItem={index === faqData.length - 2}
          />
        ))}
      </StyledFAQSection>
    </MainLayouts>
  );
};

interface AccordionItemProps {
  question: string;
  answer: string;
  isOpen: boolean;
  onClick: () => void;
  isLastItem: boolean;
  isSecondLastItem: boolean;
}

const AccordionItem = ({
  question,
  answer,
  isOpen,
  onClick,
  isLastItem,
  isSecondLastItem,
}: AccordionItemProps) => {
  return (
    <StyledAccordionItem isLastItem={isLastItem} isOpen={isOpen}>
      <div className="question" onClick={onClick}>
        <h3>{question}</h3>
        <span>{isOpen ? "-" : "+"}</span>
      </div>
      <div className={`answer ${isOpen ? "open" : ""}`}>
        {isSecondLastItem ? (
          <ol>
            {answer.split("\n").map((line, i) => (
              <li key={i}>{line}</li>
            ))}
          </ol>
        ) : isLastItem ? (
          <ul>
            {answer.split("\n").map((line, i) => (
              <li key={i}>{line}</li>
            ))}
          </ul>
        ) : (
          <p>{answer}</p>
        )}
      </div>
    </StyledAccordionItem>
  );
};

export default Fulfilment;

const StyledIntro = styled.section`
  display: flex;
  justify-content: space-between;

  .left-content {
    .intro-title {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      gap: 5px;

      h1 {
        color: #28292b;
        font-family: "Benzin-Bold", sans-serif;
        font-size: 6rem;
        font-weight: bolder;
        text-transform: uppercase;
        line-height: 0.9;
        letter-spacing: -1px;
      }
    }

    .description {
      color: #28292b;
      font-family: "Inter", sans-serif;
      font-size: 24px;
      width: 649px;
      margin: 12px 0 32px;
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 24px;

      .button-gray {
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        font-family: "Inter", sans-serif;
        cursor: pointer;
        padding: 20px 40px;
        border-radius: 10px;
        background: #e5e5e5;
        border: 1px solid #e5e5e5;
        color: #28292b;
        text-decoration: none;
      }
    }
  }

  img {
    width: 30%;
    height: 20%;
  }

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

    .left-content {
      width: 100%;

      .intro-title {
        h1 {
          font-size: 40px;
        }
      }

      .description {
        width: 100%;
      }
    }

    img {
      width: 500px;
      height: 400px;
    }
  }

  @media (max-width: 500px) {
    .left-content {
      .intro-title {
        h1 {
          font-size: 22px;
        }
      }

      .description {
        font-size: 16px;
      }

      .buttons {
        flex-direction: column;

        button,
        a {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }

        .button-gray {
          padding: 15px 40px;
          font-size: 1.3rem;
        }
      }
    }

    img {
      width: 400px;
      height: 300px;
    }
  }

  @media (max-width: 400px) {
    img {
      width: 300px;
      height: 200px;
    }
  }
`;

const StyledSecondSection = styled.section`
  margin-top: 100px;

  h1 {
    text-align: center;
    color: #28292b;
    font-family: "Benzin-Medium", sans-serif;
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 48px;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 24px;

    div {
      padding: 30px;
      border-radius: 24px;
      background: #fff;

      h3 {
        margin-top: 36px;
        color: #28292b;
        font-family: "Inter", sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 130%;
      }

      p {
        color: #2c3e50;
        font-feature-settings: "liga" off, "clig" off;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }

  .fulfilment {
    width: 100%;
    margin-top: 100px;
  }

  @media (max-width: 800px) {
    .list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 24px;
    }
  }

  @media (max-width: 550px) {
    h1 {
      font-size: 32px;
    }

    .list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        h3 {
          text-align: center;
          font-size: 20px;
        }

        p {
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }
`;

const StyledThirdSection = styled.section`
  margin-top: 100px;

  h1 {
    text-align: center;
    color: #28292b;
    font-family: "Benzin-Medium", sans-serif;
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 48px;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 24px;

    div {
      padding: 30px;
      border-radius: 24px;
      background: #fff;

      img {
        width: 100%;
        height: 250px;
      }

      h3 {
        margin-top: 36px;
        color: #28292b;
        font-family: "Inter", sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 130%;
      }

      p {
        color: #2c3e50;
        font-feature-settings: "liga" off, "clig" off;
        font-family: "Inter", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }

  @media (max-width: 800px) {
    .list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      grid-column-gap: 24px;
      grid-row-gap: 24px;
    }
  }

  @media (max-width: 550px) {
    h1 {
      font-size: 32px;
    }

    .list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        h3 {
          text-align: center;
          font-size: 20px;
        }

        p {
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }
`;

const StyledFourthSection = styled.section`
  margin-top: 100px;

  h1 {
    text-align: center;
    color: #28292b;
    font-family: "Benzin-Medium", sans-serif;
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 48px;
  }

  .list {
    display: flex;
    justify-content: center;
    gap: 24px;

    .line {
      width: 160px;
      height: 12px;
      margin-top: 80px;
    }

    div {
      width: 15%;
      display: flex;
      flex-direction: column;
      gap: 40px;

      img {
        width: 168px;
        height: 168px;
      }

      p {
        text-align: center;
      }
    }
  }

  @media (max-width: 880px) {
    .list {
      .line {
        margin-top: 45px;
        width: 100px;
      }

      div {
        img {
          width: 100px;
          height: 100px;
        }
      }
    }
  }

  @media (max-width: 600px) {
    .list {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 50px;

      .line {
        margin-top: 0;
        width: 100px;
        transform: rotate(90deg);
      }

      div {
        width: 100%;
        justify-content: center;
        align-items: center;
        gap: 0;

        img {
          width: 168px;
          height: 168px;
        }

        p {
          width: 60%;
        }
      }
    }
  }

  @media (max-width: 550px) {
    h1 {
      font-size: 32px;
    }
  }
`;

const StyledFifthSection = styled.section`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
    color: #28292b;
    font-family: "Benzin-Medium", sans-serif;
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 48px;
  }

  .content {
    width: 80%;
    padding: 48px;
    border-radius: 20px;
    background: #fcfcfd;
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 34px;
    grid-row-gap: 50px;

    .inner {
      display: flex;
      gap: 32px;
      height: fit-content;

      img {
        width: 40px;
        height: 40px;
      }

      div {
        display: flex;
        flex-direction: column;
        gap: 4px;

        p {
          color: #23262f;
          font-family: "Inter", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          margin: 0;
        }

        span {
          color: #02447f;
          font-family: "Inter", sans-serif;
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
        }
      }
    }
  }

  @media (max-width: 787px) {
    .content {
      display: flex;
      flex-direction: column;

      .btn {
        display: flex;
        justify-content: center;
        button {
          width: fit-content !important;
        }
      }
    }
  }

  @media (max-width: 550px) {
    h1 {
      font-size: 32px;
    }
  }
`;

const PhoneInputContainer = styled.div``;

const StyledPhoneInput = styled(PhoneInput)`
  .special-label {
    display: none;
  }

  .form-control {
    background: inherit;
    color: white;
    border-radius: 6px;
    border: 1px solid #d0d5dd;
    height: 52px;
    width: 450px;
  }

  .flag-dropdown {
    background: inherit;
    border: none;
  }
`;

const StyledSeventhSection = styled.section`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;
  background: #02447f;
  border-radius: 15px;
  padding: 40px;

  h1 {
    text-align: center;
    color: white;
    font-family: "Benzin-Medium", sans-serif;
    font-size: 48px;
    font-weight: 600;
  }

  @media (max-width: 550px) {
    img {
      width: 160px;
      height: 160px;
    }

    h1 {
      font-size: 32px;
    }
  }
`;

const StyledFAQSection = styled.section`
  margin-top: 160px;

  h1 {
    text-align: center;
    color: #28292b;
    font-family: "Benzin-Medium", sans-serif;
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 64px;
  }

  @media (max-width: 550px) {
    h1 {
      font-size: 32px;
    }
  }
`;

const StyledAccordionItem = styled.div<{
  isLastItem: boolean;
  isOpen: boolean;
}>`
  border-bottom: ${({ isLastItem }) =>
    isLastItem ? "none" : "1px solid #ddd"};
  padding: 10px 0;
  transition: all 0.3s ease;

  .question {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    h3 {
      color: #282828;
      font-family: "Inter", sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 26px;
    }

    span {
      font-size: 24px;
      transition: transform 0.3s ease;
      transform: ${({ isOpen }) =>
        isOpen ? "rotate(180deg)" : "rotate(0deg)"};
    }
  }

  .answer {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.5s ease, opacity 0.5s ease;
    padding-top: 0;
    font-size: 16px;
    color: #666;
    white-space: pre-line;

    &.open {
      max-height: 500px;
      opacity: 1;
      padding-top: 10px;
    }

    ol,
    ul {
      margin-left: 20px;
    }

    li {
      margin-bottom: 5px;
    }
  }
`;
