import { PrimaryButtonStyled } from "../atoms/UI/Buttons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y } from "swiper/modules";
import styled from "@emotion/styled";

const BusinessSliderContent = ({
  onShowModalRequest,
  onShowModalRequestCall,
  onShowModalRequestChange,
}: {
  onShowModalRequest: () => void;
  onShowModalRequestCall: () => void;
  onShowModalRequestChange: () => void;
}) => {
  return (
    <SwiperStyled>
      <Swiper
        modules={[Navigation, Pagination, A11y]}
        spaceBetween={50}
        navigation
        pagination={{ clickable: true }}
        autoHeight
      >
        <StyledSwiperSlide image="/intro-bg-1.png">
          <div>
            <div className="calculater_title">
              Доставка товаров по всем городам России — быстро и надежно
            </div>

            <div className="calculater_subtitle">Из Кыргызстана</div>

            <PrimaryButtonStyled
              color="white"
              background="#94C325"
              onClick={onShowModalRequestCall}
            >
              Связаться
            </PrimaryButtonStyled>
          </div>
        </StyledSwiperSlide>

        <StyledSwiperSlide image="/intro-bg-2.png">
          <div>
            <div
              className="calculater_title"
              style={{
                color: "white",
              }}
            >
              Мы - доставляем быстро, вы платите мало
            </div>

            <div className="calculater_subtitle" style={{ color: "white" }}>
              Доставка от 45 рублей
            </div>

            <PrimaryButtonStyled
              color="white"
              background="#94C325"
              onClick={onShowModalRequestCall}
            >
              Связаться
            </PrimaryButtonStyled>
          </div>
        </StyledSwiperSlide>

        <StyledSwiperSlide image="/intro-bg-3.png">
          <div>
            <div
              className="calculater_title"
              style={{
                color: "white",
              }}
            >
              Ежедневные отправки
            </div>

            <div
              className="calculater_subtitle"
              style={{
                color: "white",
              }}
            >
              По всем гордам России
            </div>

            <PrimaryButtonStyled
              color="white"
              background="#94C325"
              onClick={onShowModalRequestCall}
            >
              Связаться
            </PrimaryButtonStyled>
          </div>
        </StyledSwiperSlide>

        <StyledSwiperSlide image="/intro-bg-4.png">
          <div>
            <div
              className="calculater_title"
              style={{
                color: "white",
              }}
            >
              Выездная группа которая заберет ваш товар из цеха, магазина и
              рынка
            </div>

            <div
              className="calculater_subtitle"
              style={{
                color: "white",
              }}
            >
              И отправит в Россию
            </div>

            <PrimaryButtonStyled
              color="white"
              background="#94C325"
              onClick={onShowModalRequestChange}
            >
              Вызвать
            </PrimaryButtonStyled>
          </div>
        </StyledSwiperSlide>

        <StyledSwiperSlide image="/intro-bg-5.png">
          <div>
            <div
              className="calculater_title"
              style={{
                color: "white",
              }}
            >
              Фулфилмент под ключ
            </div>

            <PrimaryButtonStyled
              color="white"
              background="#94C325"
              onClick={onShowModalRequestCall}
            >
              Связаться
            </PrimaryButtonStyled>
          </div>
        </StyledSwiperSlide>
      </Swiper>
    </SwiperStyled>
  );
};

const SwiperStyled = styled.div`
  .swiper-button-next,
  .swiper-button-prev {
    color: #8c8c8c;
  }

  .swiper-pagination {
    bottom: 3rem;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 10px;
    height: 10px;
  }

  .swiper-pagination-bullet {
    background: #8c8c8c;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: #02447f;
  }

  @media (max-width: 768px) {
    margin-bottom: 2rem;
    .swiper-pagination {
      bottom: 0rem;
    }
  }
`;

const StyledSwiperSlide = styled(SwiperSlide)<{ image: string }>`
  background-image: url(${({ image }) => image});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px !important;
  color: black;

  @media (max-width: 768px) {
    background-size: cover;
  }

  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
  }

  .calculater_title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
  }

  .calculater_subtitle {
    font-size: 18px;
    margin-bottom: 1rem;
    text-align: center;
  }
`;

export default BusinessSliderContent;
